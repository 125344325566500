<template>
  <div class="grid p-fluid">
    <div class="col-12 md:col-12">
      <div class="card">
        <SurgeryFilter
            @filter-changed="onSubmitFilter"
            :filterCount="filterCountString"
        />
      </div>
    </div>
    <div class="col-12 md:col-8">
      <div class="card">
        <div>
          <h3>
            Hypotension Distribution
          </h3>
          <h5>
            Distribution of the duration of hypotension for selected cases
          </h5>
        </div>
        <div v-if="showHistogramLoadingSpinner" style="text-align: center; position: relative;margin:27.4%; height:100%" class="flex justify-content-center" >
          <ProgressSpinner style="position: relative; height:100%" />
        </div>
        <IohDurationHistogram
            v-else
            :cases="visibleSurgeryTableRows"
            @selectionChanged="selectionChanged"
        ></IohDurationHistogram>
      </div>
    </div>
    <div class="col-12">
      <div class="card">
        <h5>Selected cases</h5>
        <DataTable :value="selectedSurgeryTableRows" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="Id" :rowHover="true"
                   responsiveLayout="scroll"
                   selectionMode="single" @row-select="selectRow" :allowUnsorting="true"
                   sortField="SGS_StartTime" :sortOrder="1">
          <template #empty>
            No cases selected.
          </template>
          <template #loading>
            Loading cases. Please wait.
          </template>
          <Column header="Date" filterField="SGS_StartTime" dataType="date" field="SGS_StartTime" :sortable="true">
            <template #body="{data}">
              {{ data.SGS_StartTime.toLocaleString("en-US",
                {
                  day: "numeric",
                  weekday: "short",
                  month: "short",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit"
                })
              }}
            </template>
            <template #filter="{filterModel}">
              <Calendar v-model="filterModel.value" />
            </template>
          </Column>
          <Column field="STS_IOH_Under65" header="MAP Duration &lt;65mmHg (mins)" dataType="numeric" style="min-width:12rem" :sortable="true">
            <template #body="{data}">
              {{ Math.round(data.STS_IOH_Under65 / 60) }}
            </template>
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value"  class="p-column-filter" mode="numeric" placeholder="Duration"/>
            </template>
          </Column>
          <Column field="SPI_SurgeryType" header="Surgery Type" style="min-width:12rem">
            <template #body="{data}">
              {{data.SPI_SurgeryType}}
            </template>
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Surgery type"/>
            </template>
          </Column>
          <Column field="SGS_FacilityName" filterField="SGS_FacilityName" header="Facility" dataType="text" style="min-width:12rem">
            <template #body="{data}">
              {{ data.SGS_FacilityName }}
            </template>
            <template #filter="{filterModel}">
              <Dropdown v-model="filterModel.value" :options="facilities" placeholder="Any" class="p-column-filter" :showClear="true">
                <template #value="slotProps">
                  <span :class="'customer-badge status-' + slotProps.value" v-if="slotProps.value">{{slotProps.value}}</span>
                  <span v-else>{{slotProps.placeholder}}</span>
                </template>
                <template #option="slotProps">
                  <span :class="'customer-badge status-' + slotProps.option">{{slotProps.option}}</span>
                </template>
              </Dropdown>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <CaseDetailDialog
      :case="selectedCase"
      :timeMAP="timeMAP"
      :timeCO="timeCO"
      :timeSVR="timeSVR"
      :timeHR="timeHR"
      :timeSLU="timeSLU"
      :eventsData="eventsData"
      :timeTargets="timeTargets"
      style="width: 80vw"
      v-model:visible="showCaseDialog"></CaseDetailDialog>
  </div>
</template>

<script>
import SurgeryFilter from "../components/SurgeryFilter.vue";
import {API} from "aws-amplify";
import CaseDetailDialog from "./CaseDetailDialog";
import {parseISO} from "date-fns";
import IohDurationHistogram from "./IohDurationHistogram";
export default {
  data() {
    return {
      timeMAP: [],
      timeCO: [],
      timeSVR: [],
      timeHR: [],
      timeSLU: [],
      eventsData: [],
      timeTargets: [],
      selectedCase: null,
      showCaseDialog: false,
      allSurgeryTableRows: [],
      visibleSurgeryTableRows: [],
      selectedSurgeryTableRows: [],
      showHistogramLoadingSpinner: false,
    }
  },
  mounted() {
    this.showHistogramLoadingSpinner = true;
    API
        .get("TestAPI", "/surgery-sessions", null)
        .then(response => {
          this.allSurgeryTableRows = response.map(row => {
            row.SGS_StartTime = parseISO(row.SGS_StartTime)
            return row
          });
          this.visibleSurgeryTableRows = this.allSurgeryTableRows;
          this.visibleSurgeryTableRows = this.$store.getters.getFilteredSurgeries(this.allSurgeryTableRows);
          this.facilities = [...new Set(this.visibleSurgeryTableRows.map(item => item.SGS_FacilityName))];
          this.$store.dispatch("addFacilityOptions",this.facilities.map(x=> {return { name:x , code:x };}));
          this.showHistogramLoadingSpinner = false;
        })
        .catch(error => {
          console.log(error.response);
          this.showHistogramLoadingSpinner = false;
        })
        .finally(()=>{
          this.showHistogramLoadingSpinner = false;
        });
  },
  methods: {
    selectRow(selectedRow) {
      this.selectedCase = selectedRow.data
      // The time* vars are set null to clear the plots while we wait for the data to return from API
      this.timeMAP = null
      this.timeCO = null
      this.timeHR = null
      this.timeSVR = null
      this.timeTargets = null
      this.timeSLU = null
      this.showCaseDialog = true
      API
          .get("TestAPI", `/surgery-session-events/${selectedRow.data.SGS_Id}`,null)
          .then(response => {
            this.eventsData = response
          })
          .catch(error => {
            console.log(error.response);
            this.showLoading = false;
          });
      API
          .get("TestAPI", `/surgery-session-details-minuterolling/${selectedRow.data.SGS_Id}`,null)
          .then(response => {
            this.timeMAP = response.TimeMAP
            this.timeCO = response.TimeCO
            this.timeHR = response.TimeHR
            this.timeSVR = response.TimeSVR
            this.timeTargets = response.TimeTargets
            this.timeSLU = response.TimeNormoSLU
          })
          .catch(error => {
            console.log(error.response);
            this.showLoading = false;
          });
    },
    onSubmitFilter(args) {
      this.visibleSurgeryTableRows = this.allSurgeryTableRows;
      this.selectedSurgeryTableRows = []
      if(args)
      {
        this.$store.dispatch("updateFilter",args);

      }
      else
      {
        this.$store.dispatch("emptyFilter");
      }

      this.visibleSurgeryTableRows = this.$store.getters.getFilteredSurgeries(this.allSurgeryTableRows);

    },
    selectionChanged(min, max) {
      if (!min && !max)
      {
        this.selectedSurgeryTableRows = []
        return
      }
      this.selectedSurgeryTableRows = this.visibleSurgeryTableRows.filter(
          aCase => {
            return aCase.STS_IOH_Under65 / 60 >= min && aCase.STS_IOH_Under65 / 60 <= max
          }
      )
    }
  },
  components: {
    IohDurationHistogram,
    CaseDetailDialog,
    SurgeryFilter,
  },
  computed:{
    filterCountString() {
      return `Showing ${this.visibleSurgeryTableRows.length} of ${this.allSurgeryTableRows.length} cases`
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }

}
</style>
