<template>
<form @submit="onSubmitFilter" @reset="onResetFilter"  >
    <div>
      <h3>
        Filter Cases
      </h3>
    </div>
    <div class="grid">
      <div class="col-12 md:col-2" style="max-height:75px">
      <h5>Facilities</h5>
      <MultiSelect v-model="multiselectFacilityValue" :options="multiselectFacilityValues" optionLabel="name" placeholder="Select facilities" :filter="true" class="multiselect-custom">
        <template #value="slotProps">
          <div class="country-item country-item-value" v-for="option of slotProps.value" :key="option.code">
            <div>{{option.name}}</div>
          </div>
          <template v-if="!slotProps.value || slotProps.value.length === 0">
            <div class="country-placeholder">
              Select Facilities
            </div>
          </template>
        </template>
        <template #option="slotProps">
          <div class="country-item">
            <div>{{slotProps.option.name}}</div>
          </div>
        </template>
      </MultiSelect>
      </div>
        <div class="col-12 md:col-2" style="max-height:75px">
       <h5>Start Date</h5>
    <Calendar :showIcon="true" :showButtonBar="true" v-model="startDate" date-format="dd-mm-yy"></Calendar>
    </div>
      <div class="col-12 md:col-2" style="max-height:75px">
        <h5>End Date</h5>
        <Calendar :showIcon="true" :showButtonBar="true" v-model="endDate" date-format="dd-mm-yy"></Calendar>
      </div>
      <div class="col-12 md:col-4" style="max-height:75px">
    <h5>Surgery Type</h5>
    <MultiSelect v-model="multiselectValue" :options="multiselectValues" optionLabel="name" placeholder="Select surgery types" :filter="true" class="multiselect-custom">
      <template #value="slotProps">
        <div class="country-item country-item-value" v-for="option of slotProps.value" :key="option.code">
          <div>{{option.name}}</div>
        </div>
        <template v-if="!slotProps.value || slotProps.value.length === 0">
          <div class="country-placeholder">
            Select surgery types
          </div>
        </template>
      </template>
      <template #option="slotProps">
        <div class="country-item">
          <div>{{slotProps.option.name}}</div>
        </div>
      </template>
    </MultiSelect>
    </div>
      <div class="col-12 md:col-2" >
        <h5>Age Range</h5>
        <div class="grid" >
          <div class="col-12 md:col-5" style="max-height:25px">
            <InputNumber v-model="minAge"  :max="99" :min="18"  mode="decimal" placeholder="min"></InputNumber>
          </div>
          <div class="col-12 md:col-2" style="max-height:25px"><hr style="border: 1px solid;"/></div>
          <div class="col-12 md:col-5" style="max-height:25px">
            <InputNumber v-model="maxAge"  :max="99" :min="18"  mode="decimal" placeholder="max"></InputNumber>
          </div>
        </div>
      </div>
      <div class="col-12 md:col-2"  >
      <h5>&nbsp;</h5>
      <div class="grid button-demo">
        <div class="col-12 md:col-6" style="max-height:37px">
          <button type="submit" variant="primary"  class="p-button p-component mr-2 mb-2">Filter</button>
        </div>
        <div class="col-12 md:col-6" style="max-height:37px">
          <button type="reset" variant="danger" @click="onResetFilter" class="p-button p-button-danger mr-2 mb-2">Clear</button>
      </div>
      </div>

    </div>
      <div class="col-12 md:col-12"  >
        <div>
          {{ filterCount }}
        </div>
      </div>
    </div>
</form>
</template>

<script>

export default {
  props: {
    filterCount: String
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      minAge: null,
      maxAge: null,
      selectedSurgeryTypes: [],
      surgeryTypeOptions: [
        "Cardiac",
        "Neuro",
        "General",
        "Urology",
        "Gynecology",
        "ENT",
        "Plastics",
        "Vascular",
        "Thoracic",
        "Orthopedics",
        "Other",
      ],
      allSelected: false,
      indeterminate: false,
      oldSelected:[],
      multiselectFacilityValue:null,
      multiselectFacilityValues: [],
      multiselectValue:null,
      multiselectValues: [
        {name: 'Cardiac', code:  'Cardiac'},
        {name: 'Neuro', code: 'Neuro'},
        {name: 'General', code: 'General'},
        {name: 'Urology', code: 'Urology'},
        {name: 'Gynecology', code: 'Gynecology'},
        {name: 'ENT', code: 'ENT'},
        {name: 'Plastics', code: 'Plastics'},
        {name: 'Vascular', code: 'Vascular'},
        {name: 'Thoracic', code: 'Thoracic'},
        {name: 'Orthopedics', code: 'Orthopedics'},
        {name: 'Other', code: 'Other'}
      ],
    };
  },
  methods: {
    toggleAll(checked) {
      this.selectedSurgeryTypes = checked ? this.surgeryTypeOptions.slice() : [];
      this.allSelected = checked;
      this.indeterminate = !checked;

    },
    onSubmitFilter(event) {
      event.preventDefault();
      if (this.multiselectValue != null && this.multiselectValue.length > 0)
      {
        this.selectedSurgeryTypes = [];
        this.multiselectValue.forEach(item=>{
          this.selectedSurgeryTypes.push(item.code)
        });
      }
      else {
        this.selectedSurgeryTypes = []
      }
      if (this.multiselectFacilityValue != null && this.multiselectFacilityValue.length > 0)
      {
        this.selectedFacilities = [];
        this.multiselectFacilityValue.forEach(item=>{
          this.selectedFacilities.push(item.code)
        });
      }
      else {
        this.selectedFacilities = []
      }
      let args = {
        startDate: this.startDate,
        endDate: this.endDate,
        surgeryType: this.selectedSurgeryTypes,
        facilities:this.selectedFacilities,
        minAge: this.minAge,
        maxAge: this.maxAge
      };

      this.$emit("filter-changed",args);
    },
    onResetFilter(event) {
      event.preventDefault();
      this.startDate = null;
      this.endDate = null;
      this.minAge = null;
      this.maxAge = null;
      this.selectedSurgeryTypes = [];
      this.allSelected= false;
      this.indeterminate=false;
      this.multiselectValue=null;
      this.multiselectFacilityValue=null;
      let args = null;
      this.$emit("filter-changed", args);
    },
  },
  watch: {
    selectedSurgeryTypes(newValue, oldValue) {
      // Handle changes in individual flavour checkboxes
      if (newValue.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newValue.length === this.surgeryTypeOptions.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        if(oldValue.length===0){this.oldSelected = []}
        this.indeterminate = true
        this.allSelected = false
      }
    },
    selectedFacilities(newValue, oldValue) {
      // Handle changes in individual flavour checkboxes
      if (newValue.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newValue.length === this.surgeryTypeOptions.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        if(oldValue.length===0){this.oldSelected = []}
        this.indeterminate = true
        this.allSelected = false
      }
    }
  },
  mounted() {
    let getters  = this.$store;

    if(getters.state){
      this.indeterminate = getters.state.indeterminate;
      this.allSelected = getters.state.allSelected;
      this.selectedSurgeryTypes = getters.state.selectedSurgeryTypes;
      this.multiselectFacilityValues = getters.state.facilityOptions;
      this.startDate = getters.state.startDate;
      this.endDate = getters.state.endDate;
      this.minAge = getters.state.minAge;
      this.maxAge = getters.state.maxAge;
    }

  }


};
</script>

<style scoped lang="scss">
::v-deep(.p-multiselect) {
  min-width: 5rem;
}
::v-deep(.multiselect-custom-virtual-scroll .p-multiselect) {
  min-width: 20rem;
}
::v-deep(.multiselect-custom .p-multiselect-label) {
  padding-top: .25rem;
  padding-bottom: .25rem;
}
::v-deep(.multiselect-custom .country-item.country-item-value) {
  padding: .25rem .5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: .5rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}
::v-deep(.country-item.country-item-value) {
  padding: .25rem .5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: .5rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}
::v-deep(.multiselect-custom .country-item.country-item-value img.flag) {
  width: 17px;
}
.country-item {
  display: flex;
  align-items: center;
}
.country-item img.flag {
  width: 18px;
  margin-right: .5rem;
}
::v-deep(.multiselect-custom .country-placeholder) {
  padding: 0.25rem;
}
::v-deep(.p-chips .p-chips-token) {
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}
::v-deep(.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token) {
  margin: 0.1rem 0.5rem 0.1rem 0;
}
</style>