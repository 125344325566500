<template>
  <div style="position: relative; width:100%" >
    <svg id="histogram"></svg>
  </div>
</template>
<script>
import * as d3 from "d3";
export default {
  props: {
    cases: {
      default() {
        return []
      },
      type: Array
    }
  },
  data() {
    return {
    }
  },
  watch: {
    cases() {
      this.render()
    }
  },
  mounted() {
    this.render()
  },
  methods: {
    render() {
      // set the dimensions and margins of the graph
      const margin = {top: 20, right: 30, bottom: 40, left: 40}
      const xAxisOffset = 10
      const width = 700 - margin.left - margin.right
      const height = 350 - margin.top - margin.bottom

      // append the svg object to the body of the page
      d3.select("#histogram").selectAll("*").remove()
      const svg = d3.select("#histogram")
          .attr("viewBox", [0, 0, width + margin.left + margin.right, height + margin.top + margin.bottom])
          .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
          .append("g")
          .attr("transform",
              `translate(${margin.left},${margin.top})`);

      // X axis: scale and draw:
      const x = d3.scaleLinear()
          .domain([0, 120])     // can use this instead of 1000 to have the max of data: d3.max(data, function(d) { return +d.price })
          .range([xAxisOffset, width-xAxisOffset]);
      svg.append("g")
          .attr("transform", `translate(0, ${height})`)
          .call(d3.axisBottom(x));

      // set the parameters for the histogram
      const histogram = d3.histogram()
          .value(function(d) { return d.STS_IOH_Under65 / 60 })   // I need to give the vector of value
          .domain(x.domain())  // then the domain of the graphic
          .thresholds(d3.range(0, 120, 5)); // then the numbers of bins

      // outliers are cases with > 120mins duration
      const outlierCount = this.cases.filter(c => c.STS_IOH_Under65 / 60 > 120).length

      // And apply this function to data to get the bins
      const bins = histogram(this.cases);

      // Y axis: scale and draw:
      const y = d3.scaleLinear()
          .range([height, 0]);
      const yMax = Math.max(10, d3.max(bins, function(d) { return d.length; }))
      y.domain([0, yMax]);   // d3.hist has to be called before the Y axis obviously
      svg.append("g")
          .call(d3.axisLeft(y))
          .call(g => g.append("text")
              .attr("x", -margin.left)
              .attr("y", -margin.top/2)
              .attr("fill", "currentColor")
              .attr("text-anchor", "start")
              .text("Number of cases"));

      // append the bar rectangles to the svg element
      svg.selectAll("rect")
          .data(bins)
          .join("rect")
          .attr("x", 1)
          .attr("transform", function(d) { return `translate(${x(d.x0)} , ${y(d.length)})`})
          .attr("width", function(d) { return x(d.x1) - x(d.x0) -1})
          .attr("height", function(d) { return height - y(d.length); })
          .style("fill", d => {
            return d.x1 > 15 ? "#440154" : "#c8c7f1"
          })

      // X axis label:
      svg.append("text")
          .attr("x", width/2)
          .attr("y", height + 30)
          .attr("text-anchor", "middle")
          .attr("fill", "currentColor")
          .text("Minutes of hypotension");

      // X axis outliers count label:
      if (outlierCount && outlierCount > 0)
        svg.append("text")
            .attr("x", width - 35)
            .attr("y", height + 30)
            .attr("text-anchor", "middle")
            .attr("fill", "currentColor")
            .attr("font-size", "10px")
            .text(`${outlierCount} cases > 120min`);

      // AQI threshold line
      var lineFunc = d3.line()
          .x(function(d) { return x(d.x) })
          .y(function(d) { return y(d.y) })
      svg.append('path')
          .attr('d', lineFunc([{x: 15.1, y: 0}, {x: 15.1, y: Math.max(10, d3.max(bins, function(d) { return d.length; }))}]))
          .attr('stroke', 'currentColor')
          .style("stroke-dasharray", ("3, 3"))
          .attr('fill', 'none')

      // Legend
      var size = 12
      svg.append("rect")
          .attr("x", 0.2*width - size)
          .attr("y", -margin.top)
          .attr("width", size)
          .attr("height", size)
          .style("fill", "#440154")
      svg.append("rect")
          .attr("x", 0.65*width - size)
          .attr("y", -margin.top)
          .attr("width", size)
          .attr("height", size)
          .style("fill", "#c8c7f1")

      svg.append("text")
          .attr("x", 0.2*width + size)
          .attr("y", size/2 - margin.top)
          .style("fill", "currentColor")
          .style("font-size", "10")
          .text("Cumulative hypotension ≥ 15mins (AQI ePreop31)")
          .attr("text-anchor", "left")
          .style("alignment-baseline", "middle")

      svg.append("text")
          .attr("x", 0.65*width + size)
          .attr("y", size/2 - margin.top)
          .style("fill", "currentColor")
          .style("font-size", "10")
          .text("Cumulative hypotension < 15mins")
          .attr("text-anchor", "left")
          .style("alignment-baseline", "middle")

      // Add brushing (selection rectangle)
      svg
          .call(d3.brushX()
              .extent([ [0,0], [width,height] ])
              .on("start end", event => this.updateChart(event, x))
          )
    },
    updateChart(event, xScale) {
      let selectedCasesMinDuration = null
      let selectedCasesMaxDuration = null
      if (event.selection) {
        selectedCasesMinDuration = xScale.invert(event.selection[0])
        selectedCasesMaxDuration = xScale.invert(event.selection[1])
      }
      this.$emit("selectionChanged", selectedCasesMinDuration, selectedCasesMaxDuration)
    }
  }
};
</script>

<style scoped>
</style>
